import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CreateGroupModal } from "./CreateGroupModal";
import { AllStudentsTable } from "./AllStudentsTable";
import { AllEducatorsTable } from "./AllEducatorsTable";
import { Groups } from "./groups";
import { EDUCATOR_INTROJS_SELECTOR } from "../EducatorDashboardExcursion";
import { groupsStore } from "../../../../../../../store";
import { Tabs } from "../../../../../../../components/Tabs";
import { Search } from "../../../../../../../components/Search";
import { Skeleton } from "../../../../../../../components/Skeleton";
import { fetchUsers, getEducatorsList } from "../../../../../../../actions/user";
import { fetchGroups } from "../../../../../../../actions/group";
import { isAdminEducator } from "../../../../../../../helpers/authentication.ts";

const TAB = {
  ALL_STUDENTS: 'All Students',
  ALL_EDUCATORS: 'All Educators',
  GROUPS: 'Groups',
};

const tabs = Object.values(TAB)
  .map((tab) => ({ id: tab, content: tab }));

const renderContent = (currentTab, searchString) => {
  switch (currentTab) {
    case TAB.ALL_STUDENTS: return <AllStudentsTable searchString={searchString} />;
    case TAB.ALL_EDUCATORS: return <AllEducatorsTable searchString={searchString} />;
    case TAB.GROUPS: return <Groups searchString={searchString} />;
    default: return <AllStudentsTable searchString={searchString} />;
  }
}

export const MyStudents = () => {
  const { students, setGroups, setStudents, setEducators, studentsSorting, setStudentsPage, } = groupsStore();
  const [currentTab, setCurrentTab] = useState(TAB.ALL_STUDENTS);
  const [search, setSearch] = useState('');
  const [isCreateClassModalVisible, setIsCreateClassModalVisible] = useState(false);
  const [isFetching, setIsFetching] = useState(!students?.res?.length);

  useEffect(() => {
    setStudentsPage(1);
    fetchUsers(10, 0 , studentsSorting)
      .then((data) => {
        setStudents(data?.data ?? []);
      })
      .finally(() => {
        setIsFetching(false);
      });
    fetchGroups().then((data) => {
      setGroups(data?.data ?? []);
    });
    getEducatorsList(10, 0).then((data) => {
      setEducators(data?.data ?? []);
    });
  }, []);

  useEffect(() => {
    setSearch('');
  }, [currentTab]);

  return (
    <>
      <div className={`bg-white rounded-lg border border-softCloud p-6 shadow ${EDUCATOR_INTROJS_SELECTOR.USERS}`}>
        <div className={`flex items-center justify-end gap-2 mb-4 ${currentTab === TAB.ALL_EDUCATORS ? (isAdminEducator() ? '' : 'opacity-0 pointer-events-none') : ''}`}>
          {currentTab === TAB.GROUPS ? (
            <button
              className="text-blue-600 text-sm font-medium"
              onClick={() => setIsCreateClassModalVisible(true)}
            >
              + Create Group
            </button>
          ) : (
            <Link
              to={currentTab === TAB.ALL_EDUCATORS ? '/invite/educator' : '/invite/student'}
              className="text-blue-600 text-sm font-medium"
            >
              {currentTab === TAB.ALL_EDUCATORS ? '+ Invite educator' : '+ Invite student'}
            </Link>
          )}
        </div>

        <div className="flex items-center justify-between gap-2 mb-4">
          <Tabs
            currentTabId={currentTab}
            onChangeTab={setCurrentTab}
            tabs={tabs.filter(tab => (tab?.id === TAB.ALL_EDUCATORS ? isAdminEducator() : true))}
          />

          <Search searchString={search} onChangeSearchString={setSearch}/>
        </div>

        {isFetching ? (
          <div className="flex flex-col gap-1">
            {Array.from({ length: 6 }, (_, index) => (
              <Skeleton key={index} className="h-8 w-full" />
            ))}
          </div>
        ) : renderContent(currentTab, search)}
      </div>

      {isCreateClassModalVisible && (
        <CreateGroupModal
          onCloseModal={() => setIsCreateClassModalVisible(false)}
        />
      )}
    </>
  );
}