import { useState } from "react";
import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import toast from "react-hot-toast";
import { ReactComponent as CloseCrossIcon } from "../../../../../../../../../assets/close-cross.svg";
import { ReactComponent as Check } from '../../../../../../../../../assets/Check.svg';
import { fetchInvitedAssessments } from "../../../../../../../../inviteUsers/components/AssessivsSelect";
import { DatePicker } from "../../../../../../../../../components/DatePicker";
import { SubmitLoader } from "../../../../../../../../../components/SubmitLoader";
import { assignAssessivsToGroup } from "../../../../../../../../../actions/assessment";
import { sendEmailAboutAssessivInvitationToMultipleStudents } from "../../../../../../../../../actions/emails";
import { fetchUsers } from "../../../../../../../../../actions/user";
import { AssessivSearchMultipleInput } from "../../../../../../../../../components/AssessivSearchMultipleInput";
import { groupsStore, loginStore } from "../../../../../../../../../store";
import { ExtendedSearchSection } from "../../../../../../../../inviteUsers/components/ExtendedSearchSection";

export const AssignAssessivToGroup = ({ students, groupName, groupId, handleCloseModal }) => {
  const { fullName } = loginStore();
  const { setStudents } = groupsStore();
  const [selectedAssessivs, setSelectedAssessivs] = useState([]);
  const [dueDate, setDueDate] = useState('');
  const [showDueDate, setShowDueDate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isExtendedSearchVisible, setIsExtendedSearchVisible] = useState(false);

  const validateOptions = () => {
    if (!selectedAssessivs?.length) {
      toast.error('Select the assessivs to assign');
      return false;
    }

    if (!!selectedAssessivs?.length && showDueDate && !dueDate.endDate?.length) {
      toast.error('Select the "Expected by" date');
      return false;
    }

    return true;
  }

  const handleSubmitAssignment = (event) => {
    event.preventDefault();

    if (!groupId) {
      console.error('No groupId.');
      return;
    }

    if (!students?.length) {
      toast.error('No students in the group.');
      return;
    }

    if (validateOptions()) {
      setIsLoading(true);

      const assessivs = selectedAssessivs.map((selectedAssessiv) => ({
        assessivId: selectedAssessiv.assessivId,
        assessivVersionId: selectedAssessiv.assessivVersionId,
        dueDate: (showDueDate && dueDate?.endDate?.length) ? new Date(dueDate.endDate)?.toISOString() : undefined
      }));

      const studentsOnlyWithEmails = students?.reduce?.((acc, student) => {
        return student?.email ? [...acc, { email: student.email }] : acc;
      }, []) ?? [];

      assignAssessivsToGroup(groupId, assessivs)
        .then((data) => {
          console.log(data)
          sendEmailAboutAssessivInvitationToMultipleStudents({
            students: studentsOnlyWithEmails,
            teacherName: fullName
          }).then(() => {
              toast.success('Users are successfully assigned.');
              fetchInvitedAssessments();
              const { studentsPage, studentsSorting } = groupsStore.getState();
              fetchUsers(10, studentsPage - 1, studentsSorting).then((data) => {
                setStudents(data?.data ?? []);
              });
              setSelectedAssessivs([]);
            })
            .finally(() => setIsLoading(false));
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error?.response?.data?.message ?? error?.response?.data?.title);
        });
    }
  }

  return createPortal(
    <AnimatePresence mode="wait">
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={{
          visible: {opacity: 1},
          hidden: { opacity: 0 },
        }}
        transition={{ duration: 0.3 }}
        id="staticModal"
        data-modal-backdrop="static"
        tabIndex="-1"
        aria-hidden="true"
        className="fixed top-0 left-0 right-0 bottom-0 z-50 flex items-center justify-center w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 bg-black bg-opacity-20 "
      >
        <motion.div
          variants={{
            visible: { y: 0 },
            hidden: { y: 50 },
          }}
          transition={{ duration: 0.5, stiffness: 150 }}
          className={`relative w-full ${isExtendedSearchVisible ? 'max-w-[80%]' : 'max-w-[570px]'} max-h-full`}
          onClick={(event) => event.stopPropagation()}
        >
          <div className="relative bg-white rounded shadow">
            <div className="flex flex-col items-stretch pt-10 pb-2 px-8 rounded-t">
              <h2 className="text-xl text-center font-medium text-navySmoke mb-2">
                Assign to {groupName}
              </h2>
              <button
                type="button"
                className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm w-5 h-5 ml-auto inline-flex justify-center items-center"
                data-modal-hide="staticModal"
                onClick={handleCloseModal}
              >
                <CloseCrossIcon className="w-3 h-3" />
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <form
              className={`flex flex-col flex-stretch px-8 pb-10 ${isExtendedSearchVisible ? 'grid grid-cols-2' : 'flex flex-col items-center'}`}
              onSubmit={handleSubmitAssignment}
            >
              <div className={`flex gap-4 flex-col items-center ${isExtendedSearchVisible ? '' : 'w-[400px]'}`}>
                <div className="w-[400px]">
                  <AssessivSearchMultipleInput
                    email=""
                    selectedAssessivs={selectedAssessivs}
                    setSelectedAssessivs={setSelectedAssessivs}
                  />
                </div>

                <label
                  className="w-[400px] flex items-center gap-2 text-sm text-navySmoke relative cursor-pointer select-none -my-1">
                  <input
                    className="absolute w-0 h-0 opacity-0"
                    type="checkbox"
                    checked={showDueDate}
                    onChange={() => setShowDueDate(!showDueDate)}
                  />

                  <div
                    className={`flex items-center justify-center w-3.5 h-3.5 border rounded ${showDueDate ? 'bg-skyBlue border-skyBlue' : 'border-whisperBlue bg-transparent'}`}>
                    {showDueDate && <Check className="scale-90"/>}
                  </div>

                  Due Date
                </label>

                {showDueDate && (
                  <div className="w-[400px]">
                    <DatePicker
                      dueDate={dueDate}
                      setDueDate={setDueDate}
                    />
                  </div>
                )}

                <label
                  className="w-[400px] flex items-center gap-2 text-sm text-navySmoke relative cursor-pointer select-none -my-1">
                  <input
                    className="absolute w-0 h-0 opacity-0"
                    type="checkbox"
                    checked={isExtendedSearchVisible}
                    onChange={() => setIsExtendedSearchVisible(!isExtendedSearchVisible)}
                  />

                  <div
                    className={`flex items-center justify-center w-3.5 h-3.5 border rounded ${isExtendedSearchVisible ? 'bg-skyBlue border-skyBlue' : 'border-whisperBlue bg-transparent'}`}>
                    {isExtendedSearchVisible && <Check className="scale-90"/>}
                  </div>

                  Extended Search
                </label>
              </div>

              {isExtendedSearchVisible && (
                <ExtendedSearchSection
                  hideTitle
                  selectedAssessivs={selectedAssessivs}
                  setSelectedAssessivs={setSelectedAssessivs}
                />
              )}

              <button
                type="submit"
                disabled={isLoading}
                className="mx-auto mt-5 inline-block col-span-2 bg-blue-600 hover:bg-blue-700 disabled:bg-gray-200 text-white font-bold py-2 px-4 rounded-lg w-[400px] disabled:text-gray-400 disabled:font-normal"
              >
                {isLoading
                  ? <SubmitLoader/>
                  : <span className="py-2 px-4 text-md">ASSIGN</span>}
              </button>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>,
    document.body
  );
}