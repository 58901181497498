import { Highlighter, SelectionProvider } from 'react-selection-highlighter';

export const HighlightableSafeHTML = ({ content, className }) => {

  return (
    <SelectionProvider>
      <Highlighter
        htmlString={content}
        minSelectionLength={4}
        selectionWrapperClassName="group relative bg-yellow-300"
        PopoverClassName="invisible group-hover:visible absolute left-0 bottom-5 z-1 transition-all"
      />
    </SelectionProvider>
  );
}