import { Fragment, useState } from "react";
import { createPortal } from "react-dom";
import { Transition } from "@headlessui/react";
import { ExpandedQuestionRow, QuestionPreview, QuestionPreviewExpanded } from "./QuestionPreview";
import { ExpandButtonWrapper } from "../../../../dashboard/sections/home/components/general/ExpandedSection";

const previewLayoutId = 'questionPreview';

export const QuestionPreviewModal = ({
  question,
  answer,
  index,
  isPreviewVisible,
  setIsPreviewVisible,
}) => {
  const [selectedId, setSelectedId] = useState(null);
  const [isExplanationVisible, setIsExplanationVisible] = useState(false);

  const closePreview = () => setIsPreviewVisible(false);

  const handleExpandComponent = (layoutId) => (event) => {
    event.stopPropagation();
    setSelectedId(layoutId);
  }

  const renderExpandedContent = () => {
    switch (selectedId) {
      case previewLayoutId: return (
        <QuestionPreviewExpanded
          isCorrect={answer.isCorrect}
          question={question}
          isCorrectAnswerAndExplanationVisible={isExplanationVisible}
          onMakeExplanationVisible={setIsExplanationVisible}
          questionOrder={index + 1}
          userChoiceIndex={answer.userSelectedValueIndex}
          assessivCrossOutOptionResponses={answer?.assessivCrossOutOptionResponses}
        />
      );
      default: return <></>;
    }
  }

  return (
    <Transition
      show={isPreviewVisible}
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <div
        className="absolute top-7 left-0 z-10 w-full flex justify-center overflow-y-hidden"
      >
        <ExpandButtonWrapper
          stopPropagationMouseUp
          buttonClassName="absolute top-4 right-6"
          containerClassName="w-96 max-w-[556px] h-max max-h-[340px] overflow-y-auto shadow-lg scrollbar-track-slate-300 scrollbar-thin scrollbar-thumb-black/5 scrollbar-thumb-rounded"
          layoutId={previewLayoutId}
          onClickExpand={handleExpandComponent(previewLayoutId)}
        >
          <QuestionPreview
            isMinimized
            isCorrectAnswerAndExplanationVisible={isExplanationVisible}
            onMakeExplanationVisible={setIsExplanationVisible}
            isCorrect={answer.isCorrect}
            question={question}
            questionOrder={index + 1}
            userChoiceIndex={answer.userSelectedValueIndex}
            onClickOutside={closePreview}
            assessivCrossOutOptionResponses={answer?.assessivCrossOutOptionResponses}
          />
        </ExpandButtonWrapper>


        {createPortal(
          <ExpandedQuestionRow
            stopPropagationMouseUp
            buttonClassName="absolute !top-6 right-6"
            className="top-0 left-0 w-screen h-screen"
            selectedId={selectedId}
            handleRetract={() => setSelectedId(null)}
            renderContent={renderExpandedContent}
          />,
          document.body
        )}
      </div>
    </Transition>
  );
}