import { useEffect, useMemo, useState } from "react";
import { postSectionComplete } from "../../../../actions/assessment";
import { sendEducatorNotificationAboutCompletedAssessiv } from "../../../../actions/emails";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { logTime, setupSectionAndFirstTask } from "../../../../store/helpers";
import { assessmentStore, loginStore, questionStore, timerStore } from "../../../../store";
import { useQuestionTimer } from "../../../../helpers/hooks/useQuestionTimer";

const sendAssessmentCompletedNotification = () => {
  const { assessments } = assessmentStore.getState();
  const { fullName, email } = loginStore.getState();
  const { assessmentId, assessmentVersionId } = assessmentStore.getState();

  const completedAssessment = assessments?.find?.((assessment) =>
    assessment?.assessmentId?.toLowerCase() === assessmentId?.toLowerCase() &&
    assessment?.assessmentVersionId?.toLowerCase() === assessmentVersionId?.toLowerCase()
  );

  if (completedAssessment?.name?.length && completedAssessment?.educatorEmail?.length && fullName?.length && completedAssessment?.educatorEmail !== email) {
    sendEducatorNotificationAboutCompletedAssessiv({
      assessmentName: completedAssessment?.name,
      educatorEmail: completedAssessment?.educatorEmail,
      studentName: fullName
    })
      .catch(console.log);
  }
}

export const uploadNextSection = (nextSectionIndex) => {
  const { setNewQuestion, sectionOrder } = questionStore.getState();
  const {
    setSectionExpand,
    sectionData,
    setSubSectionData,
    setResultsReady,
    assessmentId,
    assessmentVersionId,
    educatorId,
    addAnsweredQuestion,
  } = assessmentStore.getState();

  const nextSection = sectionData?.[nextSectionIndex ?? sectionOrder];

  if (nextSection) {
    if (nextSection.isComplete) {
      return uploadNextSection(typeof nextSectionIndex === 'number' ? nextSectionIndex + 1 : sectionOrder);
    } else {
      return setupSectionAndFirstTask(
        {
          ...nextSection,
          sectionIndex: sectionOrder
        },
        assessmentId,
        assessmentVersionId,
        setSubSectionData,
        setNewQuestion,
        educatorId,
        addAnsweredQuestion
      )
        .then(() => {
          setSectionExpand(nextSection?.sectionId, true);
        })
        .catch((error) => console.log(error));
    }

  } else {
    sendAssessmentCompletedNotification();
    return setResultsReady(true);
  }
}

export const CompleteSection = ({ getCompleteFunction }) => {
  const { sectionOrder } = questionStore();
  const { isBreak, setIsBreak } = timerStore();
  const {
    sectionData,
    subSectionData,
    setResultsReady,
    assessmentId,
    assessmentVersionId,
    educatorId,
    answeredQuestions,
    setSectionComplete
  } = assessmentStore();

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const areAllQuestionsAnswered = useMemo(() => {
    return subSectionData?.every?.(question => answeredQuestions.includes(question.questionId)) ?? false;
  }, [subSectionData, answeredQuestions]);

  const uploadNextSectionOrBreak = (currentSection, nextSection) => {
    if (!nextSection) {
      uploadNextSection();
      return;
    }

    if (currentSection?.subjectId === nextSection?.subjectId) {
      uploadNextSection();
    } else {
      setIsBreak(true);
    }
  }

  const handleCompleteSection = async () => {
    const currentSection = sectionData?.[sectionOrder - 1];

    if (currentSection?.isComplete) {
      setResultsReady(true);
      return;
    }

    try {
      await logTime();
      await postSectionComplete(currentSection?.sectionId, assessmentId, assessmentVersionId, educatorId);

      setSectionComplete(currentSection?.sectionId);
      setConfirmModalVisible(false);

      const nextSection = sectionData?.[sectionOrder];
      uploadNextSectionOrBreak(currentSection, nextSection);
    } catch (error) {
      console.error("Error completing section:", error);
    }
  }

  const handleCloseModal = (event) => {
    event.stopPropagation();
    setConfirmModalVisible(false);
    uploadNextSection();
  }

  const handleOnClickCompleteSection = (event) => {
    if (areAllQuestionsAnswered) {
      handleCompleteSection();
      handleCloseModal(event);
    } else {
      setConfirmModalVisible(true);
    }
  }

  useEffect(() => {
    getCompleteFunction?.(handleCompleteSection)
  }, [handleCompleteSection]);

  useQuestionTimer();

  return (
    <>
      {confirmModalVisible && (
        <ConfirmationModal
          description="You have questions left without an answer. Please make sure to answer all of questions before completing the section for the best score."
          checkBoxText="I am aware of my unanswered questions"
          confirmButtonText="Complete"
          title="Complete section"
          handleConfirm={handleCompleteSection}
          handleCloseModal={handleCloseModal}
        />
      )}

      <button
        disabled={isBreak}
        className="rounded-lg border border-skyBlue bg-skyBlue text-white px-4 py-1 text-sm active:opacity-75 disabled:bg-transparent disabled:border-gray-500 disabled:text-gray-500"
        onClick={handleOnClickCompleteSection}
      >
        Complete the section
      </button>
    </>
  );
}