import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Switch } from "@headlessui/react";
import { DrawingCanvas } from "./DrawingCanvas";
import { ReactComponent as ExpandIcon } from "../../../../../assets/studentPage/expand.svg";
import { OTPInput } from "../../../../../components/OTPInput";
import { SafeHTML } from "../../../../../helpers/safeHtml";
import { useClickOutside } from "../../../../../helpers/hooks/useClickOutside.ts";
import {
  HighlightableSafeHTML,
} from "../../../../../components/highlightableSafeHTML";

const Alphabets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

const ChoiceItem = (props) => {

  const isCrossOut = !!props?.isCrossOut;

  const showIncorrect = props.optionType !== 'grid-in' && !!props.userChoiceIndex?.length && (Number(props.userChoiceIndex) === props.answerIndex) && !props.markedCorrect;

  return (
    <>
      <div className={`flex flex-row group ${(!!props.isIncorrectAnswerVisible && isCrossOut) ? 'line-through' : ''} ${props.isIncorrectAnswerVisible && showIncorrect ? '!bg-red-100 !border-red-600 text-gray-900' : ''} ${props.optionType === 'grid-in' ? 'items-center' : (props.isMinimized ? 'max-w-xs' : 'max-w-xl')} gap-3 border border-gray-300 active:bg-blue-200 hover:border-blue-300 hover:bg-blue-100 p-2 rounded-md cursor-pointer ${props.markedCorrect ? "bg-blue-100 border-blue-300" : null}`}>
        <div
          className={`flex shrink justify-center items-center h-6 min-w-[1.6em] px-1 border border-gray-400 rounded text-gray-900 ${props.isIncorrectAnswerVisible && showIncorrect ? '!bg-red-600 !border-red-600 !text-white' : ''} ${props.markedCorrect && props.optionType === 'options' ? '!text-white bg-blue-600 border-blue-600' : 'bg-gray-100'} ${props.optionType === 'grid-in' ? 'w-[96px] h-8' : 'group-hover:bg-blue-600 group-hover:text-white group-hover:border-blue-600'} ${props.markedCorrect && props.optionType === 'grid-in' ? '!border-blue-600 !text-white !bg-blue-600' : ''}`}>
          {props.index}
        </div>
        <div className="flex-1">
          {props.optionType === 'grid-in' ? (
            props.children?.length > 6 ? (
              <div className="flex items-center min-w-[32px] h-8 border border-slate-300 rounded px-2 bg-white max-w-min">
                <SafeHTML html={props.children?.replaceAll('&nbsp;', ' ')} />
              </div>
            ) : (
              <OTPInput disabled slotClassName="bg-gray-100 !border-gray-400" value={props.children} />
            )
          ) : (
            <SafeHTML html={props.children?.replaceAll('&nbsp;', ' ')} />
          )}
        </div>
      </div>
    </>
  );
};

const Explanation = ({ className = '', explanation, defaultVisible, onMakeVisible }) => {
  const [isVisible, setIsVisible] = useState(!!defaultVisible);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  const toggleVisible = () => {
    const newValue = !isVisible;
    setIsVisible(newValue);
    onMakeVisible?.(newValue);
  }

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(isVisible ? contentRef.current.scrollHeight : 0);
    }
  }, [contentRef.current, isVisible]);

  return (
    <div className={`flex flex-col gap-4 ${className}`}>
      <div className="flex items-center gap-2" onClick={toggleVisible}>
        <button className="text-gray-900 self-start text-sm font-semibold">
          View Correct Answer and Explanation
        </button>
        <Switch
          checked={isVisible}
          className={`${isVisible ? 'bg-blue-500' : 'bg-blue-100'}
          relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
        >
          <span className="sr-only">Email notifications</span>
          <span
            aria-hidden="true"
            className={`${isVisible ? 'translate-x-6' : 'translate-x-0'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>

      {!!explanation?.length && (
        <div
          ref={contentRef}
          className="text-gray-900"
          style={{
            height: isVisible ? `${contentHeight}px` : '0px',
            overflow: 'hidden',
            transition: 'height 100ms ease'
          }}
        >
          <SafeHTML html={explanation}/>
        </div>
      )}
    </div>
  );
};

const PassageDetail = ({passage}) => {
  return (
    <>
      <div className="text-gray-600 mt-4 relative bg-slate-100 rounded-lg">
        <div className="p-4">
          <HighlightableSafeHTML className="prose" content={passage?.replaceAll('&nbsp;', ' ')} />
        </div>
      </div>
    </>
  );
};

export const QuestionPreview = ({
  question,
  isCorrect,
  questionOrder,
  onMakeExplanationVisible,
  userChoiceIndex = -1,
  onClickOutside,
  isMinimized,
  isExpanded,
  isCorrectAnswerAndExplanationVisible,
  assessivCrossOutOptionResponses,
}) => {
  const ref = useRef(null);

  const areAnswersHidden = !isCorrectAnswerAndExplanationVisible && question?.answerChoicesResponse?.type === 'grid-in';

  useClickOutside(ref, onClickOutside);

  const isMarkedCorrect = (optionId) => {
    return isCorrectAnswerAndExplanationVisible ? optionId === question?.markedCorrectOptionId : false;
  }

  const isOptionCrossOut = (optionId) => {
    return !!assessivCrossOutOptionResponses?.find?.(response => response?.optionId === optionId);
  }

  return (
    <div ref={ref} className={`flex-1 cursor-auto ${isExpanded ? '' : 'border border-gray-300 rounded bg-white overflow-y-auto'} ${isMinimized ? 'p-3' : 'p-6'}`}>
      <h2 className={`text-center text-slate-700 font-medium ${isMinimized ? 'mb-2 text-base' : 'mb-6 text-md'}`}>Question {questionOrder}</h2>

      {question?.passage.hasPassage ? <PassageDetail passage={question?.passage.passageText} /> : null}

      <div className={`text-gray-900 ${question?.passage?.hasPassage ? (isMinimized ? 'mt-2' : 'mt-4') : ''}`}>
        <HighlightableSafeHTML
          className="prose"
          content={question?.prompt?.replaceAll('&nbsp;', ' ')}
        />
      </div>

      {!areAnswersHidden && (
        <div className={`flex flex-col gap-3 ${isMinimized ? 'mt-4' : 'mt-6'}`}>
          {question?.answerChoicesResponse?.questionOptionResponses?.map((item, index) => {
            return (
              <ChoiceItem
                isMinimized={isMinimized}
                userChoiceIndex={userChoiceIndex}
                answerIndex={index}
                optionType={question?.answerChoicesResponse.type}
                index={question?.answerChoicesResponse.type === "options" ? Alphabets[index] : index === 0 ? 'Answer' : 'Accepted'}
                key={index}
                markedCorrect={isMarkedCorrect(item?.optionId)}
                isIncorrectAnswerVisible={isCorrectAnswerAndExplanationVisible}
                isCrossOut={isOptionCrossOut(item.optionId)}
              >
                {item.description}
              </ChoiceItem>
            );
          })}
        </div>
      )}

      <Explanation
        key={question?.questionId}
        className={isMinimized ? 'mt-4' : 'mt-6'}
        explanation={question?.explanation?.replaceAll('&nbsp;', ' ') ?? ''}
        defaultVisible={isCorrectAnswerAndExplanationVisible}
        onMakeVisible={onMakeExplanationVisible}
      />
    </div>
  );
}

export const QuestionPreviewExpanded = (props) => {
  const containerRef = useRef(null);
  const questionPreviewRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    const container = containerRef.current;
    const questionPreviewDiv = questionPreviewRef.current;
    if (!container || !questionPreviewDiv) return;

    const updateHeight = () => {
      if (container) {
        setContainerHeight(containerRef.current.scrollHeight)
      }
    };

    const resizeObserver = new ResizeObserver(updateHeight);
    resizeObserver.observe(questionPreviewDiv);

    const mutationObserver = new MutationObserver(updateHeight);
    mutationObserver.observe(questionPreviewDiv, {
      childList: true,
      subtree: true,
      characterData: true
    });

    updateHeight();

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, []);

  return (
    <div ref={containerRef} className="flex items-stretch max-h-full flex-1 bg-white overflow-y-auto scrollbar-thumb-gray-300 scrollbar-track-gray-200 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md relative">
      <div className="w-1/2 flex-shrink-0">
        <div ref={questionPreviewRef}>
          <QuestionPreview {...props} isMinimized isExpanded />
        </div>
      </div>
      <DrawingCanvas
        canvasHeight={containerHeight}
        questionId={props?.question?.questionId}
      />
    </div>
  );
}

const backdropVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

const backdropTransition = {
  duration: 0.3
};

export const ExpandedQuestionRow = ({
  className = '',
  buttonClassName = '',
  renderContent,
  handleRetract,
  selectedId,
  stopPropagationMouseUp,
}) => {
  const ref = useRef(null);

  const handleStopPropagation = (event) => {
    event.stopPropagation();
  }

  return (
    <AnimatePresence>
      {!!selectedId && (
        <motion.div
          className={`fixed top-0 pt-14 left-0 right-0 bottom-0 z-50 h-screen flex items-stretch justify-center w-full md:inset-0 ${className}`}
          onMouseUp={stopPropagationMouseUp ? handleStopPropagation : undefined}
        >
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={backdropTransition}
            variants={backdropVariants} className="absolute inset-0 bg-black bg-opacity-20 z-[1]"
          />

          <motion.div
            ref={ref}
            layoutId={selectedId}
            className="flex-1 flex relative z-[2]"
          >
            {renderContent()}

            <button className={`absolute top-3 right-8`} onClick={handleRetract}>
              <ExpandIcon className="h-4 w-4" />
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}