import create from "zustand";
import { persist } from "zustand/middleware";

import { subSections } from "../pages/data";
import { postResult, getResult } from "../actions/assessment";

const regPageStore = create((set, get) => ({
  regPage: 0,
  nextPage: () => set((state) => ({ regPage: state.regPage + 1 })),
  resetScreen: () => set({ regPage: 0 }),
}));

const loginStore = create(
  persist(
    (set, get) => ({
      companyLogo: '',
      setCompanyLogo: (companyLogo) => set((state) => ({ companyLogo })),
      fullName: "",
      setFullName: (newFullName) => set((state) => ({ fullName: newFullName })),
      email: "",
      setEmail: (newEmail) => set((state) => ({ email: newEmail })),
      id: "",
      setId: (newId) => set((state) => ({ id: newId })),
      applicationToken: "",
      setApplicationToken: (newApplicationToken) => set((state) => ({ applicationToken: newApplicationToken })),
      refreshToken: "",
      setRefreshToken: (newRefreshToken) => set((state) => ({ refreshToken: newRefreshToken })),
      expireAt: "",
      emailNotificationsEnabled: true,
      setEmailNotificationsEnabled: (emailNotificationsEnabled) => set((state) => ({ emailNotificationsEnabled })),
      setExpireAt: (newExpireAt) => set((state) => ({ expireAt: newExpireAt })),
      storeToLocal: async (data) => {
        localStorage.setItem("applicationToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        localStorage.setItem("expireAt", data.expireAt);
        set({ applicationToken: data });
      },
      clearToken: async () => {
        localStorage.removeItem("applicationToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expireAt");
        set({ applicationToken: "", emailNotifications: true });
        window.location.href = "/login";
      },
    }),
    { name: "Assessiv-client" }
  )
);

const logoutStore = create((set) => ({
  loggedOut: false,
  handleSignOut: async (customNavigateFunction) => {
    resetStores();
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expireAt");
    localStorage.removeItem("applicationToken");
    set({ loggedOut: true });
    if (typeof customNavigateFunction === 'function') {
      customNavigateFunction?.();
    } else {
      window.location.href = "/login";
    }
  },
}));

const timerStore = create((set, get) => ({
  timeSpent: 0,
  isBreak: false,

  setTimeSpent: (timeSpent) => {
    if (typeof timeSpent === 'function') {
      set({ timeSpent: timeSpent(get().timeSpent) })
    } else {
      set({ timeSpent: timeSpent });
    }
  },

  setIsBreak: (isBreak) => {
    if (typeof isBreak === 'function') {
      set({ isBreak: isBreak(get().isBreak) })
    } else {
      set({ isBreak: isBreak });
    }
  },

  resetTimeSpent: () => {
    set({
      timeSpent: 0,
      isBreak: false
    });
  },
}));

const questionStore = create((set, get) => ({
  questionOrder: 0,
  questionSelected: "",
  sectionIndex: 0,
  sectionName: "",
  sectionId: "",
  sectionOrder: "",
  optionSelected: '',
  isTimerPaused: false,
  isStrikeMode: false,

  resetQuestionStore: () => {
    set({
      questionOrder: 0,
      questionSelected: "",
      sectionIndex: 0,
      sectionName: "",
      sectionId: "",
      sectionOrder: "",
      optionSelected: '',
      isTimerPaused: false,
      isStrikeMode: false,
    });
  },

  setStrikeMode: (isStrikeMode) => {
    set({ isStrikeMode });
  },

  setIsTimerPaused: (isTimerPaused) => {
    set({ isTimerPaused });
  },

  setQuestionSelected: (questionSelected) => {
    set({ questionSelected });
  },

  setOptionSelected: (optionSelected) => {
    set({ optionSelected });
  },

  setNewQuestion: ({ questionSelected, questionOrder, sectionName, sectionOrder, sectionId }) => {
    set({ questionSelected, questionOrder, sectionName, sectionOrder, sectionId });
  },
}));

const assessmentStore = create(
  persist(
    (set, get) => ({
      assessmentId: '',
      assessmentVersionId: '',
      educatorId: '',
      accommodations: 'None',
      sectionData: subSections,
      subSectionData: null,
      assessments: [],
      completedAssessments: [],
      publishedAssessments: [],
      answeredQuestions: [],
      studentsResults: [],
      isFetchingStudentResults: false,
      bestScores: {},
      educatorScores: {},
      areasForImprovement: {},

      resetAssessmentStore: () => {
        set({
          assessmentId: '',
          assessmentVersionId: '',
          educatorId: '',
          accommodations: 'None',
          sectionData: subSections,
          subSectionData: null,
          assessments: [],
          completedAssessments: [],
          publishedAssessments: [],
          answeredQuestions: [],
          studentsResults: [],
          bestScores: {},
          educatorScores: {},
          resultsReady: false,
          studentEmailResult: null,
          studentAnswerVersionResult: null,
          isLoadingAssessmentOverview: false,
          isFetchingStudentResults: false,
          studentCompletedVersions: [],
          results: null,
          notifications: [],
          areasForImprovement: {},
        });
      },

      setIsFetchingStudentResults: (isFetchingStudentResults) => {
        set({ isFetchingStudentResults });
      },

      setBestScores: (bestScores) => {
        set({ bestScores });
      },

      setEducatorScores: (educatorScores) => {
        set({ educatorScores });
      },

      setAccommodations: (accommodations) => {
        set({ accommodations });
      },

      setAreasForImprovement: (areasForImprovement) => {
        set({ areasForImprovement });
      },

      setAssessments: async (assessments) => {
        set({ assessments });
      },

      setCompletedAssessments: async (completedAssessments) => {
        set({ completedAssessments });
      },

      setPublishedAssessments: async (publishedAssessments) => {
        set({ publishedAssessments });
      },

      setAssessmentId: async (assessmentId) => {
        set({ assessmentId });
      },

      setAssessmentVersionId: async (assessmentVersionId) => {
        set({ assessmentVersionId });
      },

      setEducatorId: async (educatorId) => {
        set({ educatorId });
      },

      setSubSectionData: async (subSectionData) => {
        set({ subSectionData, answeredQuestions: [] });
      },

      setAnsweredQuestions: async (answeredQuestions) => {
        set({ answeredQuestions });
      },

      addAnsweredQuestion: async (questionId) => {
        set((state) => ({ answeredQuestions: [...state.answeredQuestions, questionId] }));
      },

      setSectionData: async (sectionData) => {
        set({ sectionData });
      },

      setSectionComplete: async (sectionId) => {
        set((state) => ({ sectionData: state.sectionData.map(section => section.sectionId === sectionId ? { ...section, isComplete: true } : section) }));
      },

      setStudentsResults: async (studentsResults) => {
        set({ studentsResults })
      },

      resultsReady: false,
      setResultsReady: async (ready) => {
        set({ resultsReady: ready });
      },

      studentEmailResult: null,
      setStudentEmailResult: async (studentEmailResult) => {
        set({ studentEmailResult });
      },

      studentAnswerVersionResult: null,
      setStudentAnswerVersionResult: async (version) => {
        set({ studentAnswerVersionResult: version });
      },

      isLoadingAssessmentOverview: false,
      setIsLoadingAssessmentOverview: async (isLoadingAssessmentOverview) => {
        set({ isLoadingAssessmentOverview });
      },

      studentCompletedVersions: [],
      setStudentCompletedVersions: async (studentCompletedVersions) => {
        set({ studentCompletedVersions });
      },

      results: null,
      postResults: async (answerData) => {
        return new Promise((resolve, reject) => {
          postResult(answerData)
            .then((res) => {
              resolve(res);
              set({ results: res });
            })
            .catch((err) => {
              reject(err);
              console.log(err);
            });
        });
      },

      resetResults: async () => {
        set({ results: null });
      },

      getResults: async ({ assessivId, assessivVersionId, educatorId, studentEmail, answerVersion }) => {
        return new Promise((resolve, reject) => {
          getResult({ assessivId, assessivVersionId, educatorId, studentEmail, answerVersion })
            .then((res) => {
              resolve(res);
              set({ results: res?.data ?? null });
            })
            .catch((err) => {
              reject(err);
              console.log(err);
            });
        });
      },
      setResults: async (results) => {
        set({ results });
      },

      setSectionExpand: async (sId, expandedState) => {
        set((state) => ({ sectionData: state.sectionData.map((section) => (section?.sectionId === sId ? { ...section, isExpanded: expandedState } : section)) }));
      },

      setUnExpandAllSection: async (expandedState) => {
        set((state) => ({ sectionData: state.sectionData.map((section) => ({ ...section, isExpanded: !expandedState })) }));
      },

      showExcursion: true,
      setShowExcursion: (showExcursion) => {
        set({ showExcursion });
      },

      notifications: [],
      setNotifications: (newNotifications) => {
        if (typeof newNotifications === 'function') {
          set({ notifications: newNotifications(get().notifications) })
        } else {
          set({ notifications: newNotifications });
        }
      }
    }),
    {
      name: "assessmentStore",
      getStorage: () => localStorage,
    }
  )
);

const groupsStore = create(
  persist(
    (set, get) => ({
      groups: [],
      students: [],
      studentsSorting: 1,
      studentsPage: 1,
      educators: [],
      studentsApprovalList: [
        {
          id: 1,
          name: 'Sadie Lockman',
          email: 'sadie@example.com',
          sentDate: '2022-09-20',
          groupId: 3,
        },
        {
          id: 16,
          name: 'Ella Davis',
          email: 'ella@example.com',
          sentDate: '2023-04-09',
          groupId: 3,
        },
        {
          id: 17,
          name: 'Liam Martinez',
          email: 'liam@example.com',
          sentDate: '2022-03-01',
          groupId: 2,
        },
        {
          id: 18,
          name: 'Mia Taylor',
          email: 'mia@example.com',
          sentDate: '2021-11-19',
          groupId: 1,
        },
        {
          id: 19,
          name: 'Sophia Anderson',
          email: 'sophia@example.com',
          sentDate: '2022-08-15',
          groupId: 3,
        },
        {
          id: 20,
          name: 'Henry Smith',
          email: 'henry@example.com',
          sentDate: '2022-05-12',
          groupId: 2,
        },
        {
          id: 21,
          name: 'Ava Johnson',
          email: 'ava@example.com',
          sentDate: '2023-01-25',
          groupId: 1,
        },
        {
          id: 22,
          name: 'Oliver Brown',
          email: 'oliver@example.com',
          sentDate: '2022-07-05',
          groupId: 3,
        },
        {
          id: 23,
          name: 'Emma White',
          email: 'emma@example.com',
          sentDate: '2022-04-18',
          groupId: 2,
        },
        {
          id: 24,
          name: 'Noah Lee',
          email: 'noah@example.com',
          sentDate: '2023-02-12',
          groupId: 1,
        },
        {
          id: 25,
          name: 'Isabella Thomas',
          email: 'isabella@example.com',
          sentDate: '2022-10-08',
          groupId: 3,
        },
        {
          id: 26,
          name: 'William Johnson',
          email: 'william@example.com',
          sentDate: '2023-03-30',
          groupId: 2,
        },
        {
          id: 27,
          name: 'Olivia Harris',
          email: 'olivia@example.com',
          sentDate: '2021-12-25',
          groupId: 1,
        },
        {
          id: 28,
          name: 'Lily Clark',
          email: 'lily@example.com',
          sentDate: '2023-04-02',
          groupId: 3,
        },
        {
          id: 29,
          name: 'Lucas Martin',
          email: 'lucas@example.com',
          sentDate: '2022-06-10',
          groupId: 2,
        },
      ],

      resetGroupsStore: () => {
        set({
          groups: [],
          students: [],
          educators: [],
          studentsApprovalList: [
            {
              id: 1,
              name: 'Sadie Lockman',
              email: 'sadie@example.com',
              sentDate: '2022-09-20',
              groupId: 3,
            },
            {
              id: 16,
              name: 'Ella Davis',
              email: 'ella@example.com',
              sentDate: '2023-04-09',
              groupId: 3,
            },
            {
              id: 17,
              name: 'Liam Martinez',
              email: 'liam@example.com',
              sentDate: '2022-03-01',
              groupId: 2,
            },
            {
              id: 18,
              name: 'Mia Taylor',
              email: 'mia@example.com',
              sentDate: '2021-11-19',
              groupId: 1,
            },
            {
              id: 19,
              name: 'Sophia Anderson',
              email: 'sophia@example.com',
              sentDate: '2022-08-15',
              groupId: 3,
            },
            {
              id: 20,
              name: 'Henry Smith',
              email: 'henry@example.com',
              sentDate: '2022-05-12',
              groupId: 2,
            },
            {
              id: 21,
              name: 'Ava Johnson',
              email: 'ava@example.com',
              sentDate: '2023-01-25',
              groupId: 1,
            },
            {
              id: 22,
              name: 'Oliver Brown',
              email: 'oliver@example.com',
              sentDate: '2022-07-05',
              groupId: 3,
            },
            {
              id: 23,
              name: 'Emma White',
              email: 'emma@example.com',
              sentDate: '2022-04-18',
              groupId: 2,
            },
            {
              id: 24,
              name: 'Noah Lee',
              email: 'noah@example.com',
              sentDate: '2023-02-12',
              groupId: 1,
            },
            {
              id: 25,
              name: 'Isabella Thomas',
              email: 'isabella@example.com',
              sentDate: '2022-10-08',
              groupId: 3,
            },
            {
              id: 26,
              name: 'William Johnson',
              email: 'william@example.com',
              sentDate: '2023-03-30',
              groupId: 2,
            },
            {
              id: 27,
              name: 'Olivia Harris',
              email: 'olivia@example.com',
              sentDate: '2021-12-25',
              groupId: 1,
            },
            {
              id: 28,
              name: 'Lily Clark',
              email: 'lily@example.com',
              sentDate: '2023-04-02',
              groupId: 3,
            },
            {
              id: 29,
              name: 'Lucas Martin',
              email: 'lucas@example.com',
              sentDate: '2022-06-10',
              groupId: 2,
            },
          ]
        });
      },

      setGroups: (newValueOrFunction) => {
        if (typeof newValueOrFunction === 'function') {
          set({ groups: newValueOrFunction(get().groups) })
        } else {
          set({ groups: newValueOrFunction });
        }
      },
      setStudentsApprovalList: (studentsApprovalListOrFunction) => {
        if (typeof studentsApprovalListOrFunction === 'function') {
          set({ studentsApprovalList: studentsApprovalListOrFunction(get().studentsApprovalList) })
        } else {
          set({ studentsApprovalList: studentsApprovalListOrFunction });
        }
      },
      setStudents: (studentsOrFunction) => {
        if (typeof studentsOrFunction === 'function') {
          set({ students: studentsOrFunction(get().students) })
        } else {
          set({ students: studentsOrFunction });
        }
      },
      setEducators: (educatorsOrFunction) => {
        if (typeof educatorsOrFunction === 'function') {
          set({ educators: educatorsOrFunction(get().educators) })
        } else {
          set({ educators: educatorsOrFunction });
        }
      },
      setStudentSorting: (studentSortingOrFunction) => {
        if (typeof studentSortingOrFunction === 'function') {
          set({ studentsSorting: studentSortingOrFunction(get().studentsSorting) })
        } else {
          set({ studentsSorting: studentSortingOrFunction });
        }
      },
      setStudentsPage: (studentsPageOrFunction) => {
        if (typeof studentsPageOrFunction === 'function') {
          set({ studentsPage: studentsPageOrFunction(get().studentsPage) })
        } else {
          set({ studentsPage: studentsPageOrFunction });
        }
      },
    }),
    {
      name: "groupsStore",
      getStorage: () => localStorage,
    }
  )
);

const tablesStore = create(
  persist(
    (set, get) => ({
      resetTablesStore: () => {
        set({
          assessmentTableTab: 'Assessivs',
          studentCompletedAssessmentTableTab: 'Areas For Improvement',
          assessmentTablePage: 1,
          availableAssessmentTablePage: 1,
          completedAssessmentTablePage: 1,
          practiceAssessmentTablePage: 1,
        });
      },

      assessmentTableTab: 'Practice Assessivs',
      assessmentTablePage: 1,
      setAssessmentTableTab: async (assessmentTableTab) => {
        set({ assessmentTableTab });
      },
      setAssessmentTablePage: async (assessmentTablePage) => {
        set({ assessmentTablePage });
      },

      studentCompletedAssessmentTableTab: 'Areas For Improvement',
      setStudentCompletedAssessmentTableTab: async (studentCompletedAssessmentTableTab) => {
        set({ studentCompletedAssessmentTableTab });
      },

      availableAssessmentTablePage: 1,
      setAvailableAssessmentTablePage: async (availableAssessmentTablePage) => {
        set({ availableAssessmentTablePage });
      },

      completedAssessmentTablePage: 1,
      setCompletedAssessmentTablePage: async (completedAssessmentTablePage) => {
        set({ completedAssessmentTablePage });
      },

      practiceAssessmentTablePage: 1,
      setPracticeAssessmentTablePage: async (practiceAssessmentTablePage) => {
        set({ practiceAssessmentTablePage });
      },

      studentsTableSortingType: 'Newest',
      setStudentsTableSortingType: async (studentsTableSortingType) => {
        set({ studentsTableSortingType });
      },

      educatorsAssessmentFilterType: 'None',
      setEducatorsAssessmentFilterType: async (educatorsAssessmentFilterType) => {
        set({ educatorsAssessmentFilterType });
      },

      groupView: 'Plates',
      setGroupView: (groupView) => {
        set({ groupView });
      }
    }),
    {
      name: "tablesStore",
      getStorage: () => localStorage,
    }
  )
);

const resultsStore = create(
  persist(
    (set, get) => ({
      isSidebarOpened: false,
      currentId: 'download-page-id-1',
      isPresentMode: false,
      isTwoPageView: false,

      setIsSidebarOpened: (isSidebarOpened) => {
        set({ isSidebarOpened });
      },

      setIsPresentMode: (isPresentMode) => {
        set({ isPresentMode });
      },

      setIsTwoPageView: (isTwoPageView) => {
        set({ isTwoPageView });
      },

      setCurrentId: (currentIdOrFunction) => {
        if (typeof currentIdOrFunction === 'function') {
          set({ currentId: currentIdOrFunction(get().currentId) })
        } else {
          set({ currentId: currentIdOrFunction });
        }
      }
    }),
    {
      name: "resultsStore",
      getStorage: () => localStorage,
    }
  )
);

function resetStores() {
  const { resetQuestionStore } = questionStore.getState();
  const { resetAssessmentStore } = assessmentStore.getState();
  const { resetTimeSpent } = timerStore.getState();
  const { resetGroupsStore } = groupsStore.getState();
  const { resetTablesStore } = tablesStore.getState();
  const { setCompanyLogo, setEmailNotificationsEnabled } = loginStore.getState();

  resetQuestionStore();
  resetAssessmentStore();
  resetTimeSpent();
  resetGroupsStore();
  resetTablesStore();
  setCompanyLogo();
  setEmailNotificationsEnabled(true);
}

export { regPageStore, loginStore, logoutStore, assessmentStore, questionStore, groupsStore, tablesStore, timerStore, resultsStore, resetStores };
