import { useMemo, useRef } from "react";
import { CompleteSection, uploadNextSection } from "./CompleteSection";
import { ReactComponent as ArrowIcon } from "../../../../assets/rt-arrow.svg";
import { assessmentStore, questionStore, timerStore } from "../../../../store";
import { Timer} from "../../../../components/Timer";
import { logTime } from "../../../../store/helpers";
import { useArrowKeyNavigation } from "../../../../helpers/hooks/useArrowKeyNavigation";
import { TIME_TAG_ID } from "../../../../helpers/constants";

const BREAK_TIME_IN_SECONDS = 600;

const QNav = () => {
  const { sectionData, subSectionData } = assessmentStore();
  const { sectionId, sectionName, sectionOrder, questionOrder, setNewQuestion } = questionStore();
  const isBreak = timerStore(state => state.isBreak);
  const setIsBreak = timerStore(state => state.setIsBreak);
  const completeSectionFuncRef = useRef(null);

  const currentSection = useMemo(
    () => sectionData?.find(section => section?.sectionId === sectionId),
    [sectionData, sectionId]
  );

  const initialTimerTime = useMemo(() => {
    const sectionTime = currentSection?.sectionTags
      ?.find((tag) => tag?.tagId === TIME_TAG_ID)?.values?.[0];

    if (typeof sectionTime === 'string' && !isNaN(+sectionTime)) {
      return +sectionTime - (currentSection?.time ?? 0);
    }

    return 600;
  }, [currentSection]);

  const { previousQuestionId, nextQuestionId } = useMemo(
    () => {
      const previousQuestionIndex = questionOrder - 2;

      return {
        previousQuestionId: subSectionData?.[previousQuestionIndex]?.questionId,
        nextQuestionId: subSectionData?.[questionOrder]?.questionId
      }
    },
    [subSectionData, questionOrder]
  );

  const handleSelectedNewQuestion = (newQuestionId, newQuestionOrder) => {
    logTime();
    if (newQuestionId) {
      setNewQuestion({
        questionSelected: newQuestionId,
        sectionName,
        sectionId,
        sectionOrder,
        questionOrder: newQuestionOrder
      });
    }
  }

  const handleForceCompleteSection = () => {
    console.log('Force section complete.');
    completeSectionFuncRef?.current?.();
  }

  const handleUploadNextSection = () => {
    setIsBreak(false);
    uploadNextSection();
  }

  const handleSelectPreviousQuestion = () => {
    if (previousQuestionId) {
      handleSelectedNewQuestion(previousQuestionId, questionOrder - 1);
    }
  }

  const handleSelectNextQuestion = () => {
    if (nextQuestionId) {
      handleSelectedNewQuestion(nextQuestionId, questionOrder + 1)
    }
  }

  useArrowKeyNavigation({
    onLeftArrow: handleSelectPreviousQuestion,
    onRightArrow: handleSelectNextQuestion,
  });

  return (
    <div className="flex flex-row justify-start gap-6 flex-1 items-stretch max-w-max">
      <Timer
        showEmpty={isBreak ? false : (!currentSection || !!currentSection?.isComplete)}
        key={`${sectionId} ${initialTimerTime} ${isBreak}`}
        initialTime={isBreak ? BREAK_TIME_IN_SECONDS : initialTimerTime}
        onEnd={isBreak ? handleUploadNextSection : handleForceCompleteSection}
      />

      <div className="flex items-center gap-2">
        <button
          disabled={isBreak || !previousQuestionId}
          className="flex items-center gap-2 min-w-max rounded-lg border-fadedDenim border text-white disabled:text-fadedDenim px-4 py-1.5 text-sm font-medium disabled:opacity-75 active:opacity-75"
          onClick={handleSelectPreviousQuestion}
        >
          <span className="rotate-180">
            <ArrowIcon classname="h-5 w-5" />
          </span>
          Previous
        </button>

        <button
          disabled={isBreak || !nextQuestionId}
          className="flex items-center gap-2 min-w-max rounded-lg border-fadedDenim border text-white disabled:text-fadedDenim px-4 py-1.5 text-sm font-medium disabled:opacity-75 active:opacity-75"
          onClick={handleSelectNextQuestion}
        >
          Next
          <ArrowIcon classname="h-5 w-5" />
        </button>
      </div>

      <CompleteSection
        getCompleteFunction={(handleComplete) => {
          completeSectionFuncRef.current = handleComplete;
        }}
      />
    </div>
  );
};

export { QNav };
