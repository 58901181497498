import { Fragment, useMemo, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { groupsStore } from "../../../../../../../store";

export const ALL_STUDENTS_SORTING_OPTION = {
  CREATED_DATE: 'Created Date',
  NAME: 'Name',
  LAST_LOGIN: 'Last Login'
};

export const getStudentSortingValue = (string) => {
  switch (string) {
    case ALL_STUDENTS_SORTING_OPTION.CREATED_DATE: return 1;
    case ALL_STUDENTS_SORTING_OPTION.NAME: return 2;
    case ALL_STUDENTS_SORTING_OPTION.LAST_LOGIN: return 3;
    default: return 1;
  }
}

export const getStudentSortingNameByValue = (value) => {
  switch (value) {
    case 1: return ALL_STUDENTS_SORTING_OPTION.CREATED_DATE;
    case 2: return ALL_STUDENTS_SORTING_OPTION.NAME;
    case 3: return ALL_STUDENTS_SORTING_OPTION.LAST_LOGIN;
    default: return ALL_STUDENTS_SORTING_OPTION.CREATED_DATE;
  }
}

const sortingOptions = Object.values(ALL_STUDENTS_SORTING_OPTION);

export const AllStudentSorting = ({ className = '' }) => {
  const { studentsSorting, setStudentSorting, } = groupsStore();
  const [query, setQuery] = useState('')

  const filteredSortingOptions = useMemo(() => {
    if (query) {
      return sortingOptions.filter((option) =>
        option
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      );
    }

    return sortingOptions;
  }, [query]);

  const handleOnChange = (newValue) => {
    setStudentSorting(getStudentSortingValue(newValue));
  }

  return (
    <div className={`flex items-baseline gap-2 z-20 ${className}`}>
      <span className="text-sm text-black">Sorting:</span>
      <div className="w-36">
        <Combobox value={getStudentSortingNameByValue(studentsSorting)} onChange={handleOnChange}>
          <div className="relative mt-1">
            <div
              className="relative w-full cursor-default overflow-hidden rounded-lg bg-white border text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
              <Combobox.Input
                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                displayValue={(option) => option}
                onChange={(event) => setQuery(event.target.value)}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options
                className="absolute mt-1 max-h-[240px] w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {filteredSortingOptions.length === 0 && query !== '' ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredSortingOptions.map((option) => (
                    <Combobox.Option
                      key={option}
                      className={({active}) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? 'bg-blue-500 text-white' : 'text-gray-900'
                        }`
                      }
                      value={option}
                    >
                      {({selected, active}) => (
                        <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {option}
                        </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-blue-600'
                              }`}
                            >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
}