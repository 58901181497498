import { api } from "../../helpers/apiHelper";
import { EMAIL_SERVICE_URL } from "../constants";

export const sendEmailAboutAssessivInvitation = ({ email, password, teacherName }) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${EMAIL_SERVICE_URL}/invite/assessiv`,
        { email, password, teacherName },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendEmailAboutAssessivInvitationToMultipleStudents = ({ students, teacherName }) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${EMAIL_SERVICE_URL}/invite/assessiv/multiple`,
        { students, teacherName },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendEmailEducatorInvitation = ({ email, password, inviterName, name }) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${EMAIL_SERVICE_URL}/invite/educator`,
        { email, password, inviterName, name },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendEducatorNotificationAboutCompletedAssessiv = ({ assessmentName, studentName, educatorEmail }) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${EMAIL_SERVICE_URL}/assessment/completed/notify`,
        { assessmentName, studentName, educatorEmail },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendEducatorNotificationAboutRoleChange = ({ name, email, changedUserName, previousRole, newRole }) => {
  return new Promise((resolve, reject) => {
    api
      .post(
        `${EMAIL_SERVICE_URL}/role-change`,
        {
          name,
          email,
          changedUserName,
          previousRole,
          newRole
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};