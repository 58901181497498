import { useEffect, useMemo, useRef, useState } from "react";
import { AddOptions } from "./AddOptions";
import { SendAssignment } from "./SendAssignment";
import { AllStudentSorting } from "./AllStudentSorting";
import { fetchEducatorsScores } from "../EducatorScores";
import { assessmentStore, groupsStore } from "../../../../../../../store";
import { Abbreviator } from "../../../../../../../helpers/index.ts";
import { createDebounceFunction } from "../../../../../../../helpers/functions.ts";
import { formatDateToTimeAgo } from "../../../../../../../helpers/date.ts";
import {
  getEducatorUserScoreHistory,
} from "../../../../../../../actions/assessment";
import {
  deleteInvitedStudent,
  fetchUsers,
  fetchStudentsResults,
  searchUser
} from "../../../../../../../actions/user";
import Pagination from "../../../../../../../components/Pagination";
import { RemoveFromInvitedListModal } from "../../../../../../../components/RemoveFromInvitedListModal";
import { CompletedResultsTable } from "../../../../../../../components/completedResultsTable";
import { isAdminEducator } from "../../../../../../../helpers/authentication.ts";

const STUDENTS_PER_PAGE = 10;

export const AllStudentsTableRow = ({student}) => {
  const {setStudents} = groupsStore();
  const {setStudentsResults} = assessmentStore();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCursorWait, setIsCursorWait] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [historyOfResults, setHistoryOfResults] = useState([]);
  const tableRef = useRef(null);
  const noAssessmentsRef = useRef(null);

  const filteredHistoryOfResults = useMemo(() => {
    return historyOfResults?.filter(result => !!result?.revisions?.length);
  }, [historyOfResults]);

  const toggleExpanded = () => {
    if (!isExpanded && !historyOfResults?.length) {
      setIsCursorWait(true);
      getEducatorUserScoreHistory(student.accountId)
        .then((data) => {
          setHistoryOfResults(data?.data ?? []);
        })
        .finally(() => {
          setIsExpanded(prev => !prev);
          setIsCursorWait(false);
        });
    } else {
      setIsExpanded(prev => !prev);
    }
  };

  const handleRemoveInvitedStudent = () => {
    deleteInvitedStudent(student.accountId)
      .then(() => {
        const { studentsPage, studentsSorting } = groupsStore.getState();
        fetchUsers(10, studentsPage - 1, studentsSorting).then((data) => {
          setStudents(data?.data ?? []);
        });
        fetchStudentsResults()
          .then(data => setStudentsResults(data?.data ?? []));
        fetchEducatorsScores();
      })
      .catch(console.log);
  }

  useEffect(() => {
    if (tableRef.current) {
      setContentHeight(isExpanded ? tableRef.current.scrollHeight + 16 : 0);
    }
  }, [tableRef.current, isExpanded, historyOfResults?.length]);

  return (
    <>
      <tr
        className={`transition-all duration-300 border-t ${isExpanded ? 'border-fadedDenim/50 bg-gray-200' : 'border-alabaster hover:bg-lightGrey'} group text-center text-sm ${isCursorWait ? '!cursor-wait' : 'cursor-pointer'}`}
        onClick={toggleExpanded}
      >
        <td
          className="col-span-4 flex text-xs text-duskyHarbor items-center gap-4 text-left py-[14px] px-2 pl-6 transition-all duration-300">
          <div className="flex items-center gap-4 font-medium">
          <div
              className="flex h-6 w-6 text-white font-medium text-[10px] leading-[16px] items-center justify-center rounded-full bg-blue-500">{Abbreviator(student?.name?.toUpperCase() ?? '')}</div>
            {student?.name}
          </div>
        </td>

        <td className="col-span-2 text-center px-2 text-xs">
          {formatDateToTimeAgo(student?.lastLogin) ?? '-'}
        </td>


        <td className="col-span-2 px-2 text-xs">
          {formatDateToTimeAgo(student?.assessivInfo?.lastCompleted) ?? '-'}
        </td>

        <td className="col-span-2 px-2 text-xs">
          {student?.assessivInfo?.totalAssessivComplete ?? '-'}
        </td>

        <td className="col-span-2 px-2 text-right font-medium">
          <div className="h-full w-full flex items-center justify-center">
            {isAdminEducator() ? (
              <>
                <AddOptions
                  userId={student?.userId}
                  email={student?.email}
                  name={student?.name}
                />
                <div onClick={event => event.stopPropagation()}>
                  <RemoveFromInvitedListModal handleRemoveUser={handleRemoveInvitedStudent}/>
                </div>
              </>
            ) : (
              <div onClick={event => event.stopPropagation()}>
                <SendAssignment
                  email={student?.email}
                  name={student?.name}
                />
              </div>
            )}
        </div>
      </td>
      </tr>

      <tr>
        <td
          className={`transition-all duration-300 ${isExpanded ? 'border-b border-b-fadedDenim/50' : ''}`}
          colSpan={6}
        >
          <div style={{
            height: isExpanded ? `${filteredHistoryOfResults?.length ? contentHeight : noAssessmentsRef.current.scrollHeight}px` : '0px',
            overflow: 'hidden',
            transition: 'height 300ms ease'
          }}
          >
            <CompletedResultsTable
              containerRef={tableRef}
              noContentContainerRef={noAssessmentsRef}
              results={historyOfResults}
            />
          </div>
        </td>
      </tr>
    </>
  );
}

const loadUsers = (searchString, page, studentsSorting) => {
  const { setStudents } = groupsStore.getState();
  if (searchString) {
    searchUser(searchString, STUDENTS_PER_PAGE, (page - 1) * STUDENTS_PER_PAGE, studentsSorting)
      .then((data) => {
        setStudents(data?.data ?? [])
      });
  } else {
    fetchUsers(STUDENTS_PER_PAGE, (page - 1) * STUDENTS_PER_PAGE, studentsSorting)
      .then((data) => {
        setStudents(data?.data ?? [])
      });
  }
}

const debounceSearchStudents = createDebounceFunction(loadUsers, 600);

export const AllStudentsTable = ({searchString}) => {
  const { students , studentsSorting, studentsPage, setStudentsPage } = groupsStore();

  const handleChangePage = (newPage) => {
    setStudentsPage(newPage);
    loadUsers(searchString, newPage, studentsSorting);
  }

  useEffect(() => {
    setStudentsPage(1);
    debounceSearchStudents(searchString, 1, studentsSorting);
  }, [searchString]);

  useEffect(() => {
    setStudentsPage(1);
    loadUsers(searchString, 1, studentsSorting);
  }, [studentsSorting]);

  if (!students?.res?.length) {
    return (
      <div className="text-xl text-slate-500 text-center p-4 md:p-6 w-full">
        No students
      </div>
    );
  }

  return (
    <>
      <div
        className="overflow-auto relative scrollbar-thumb-gray-200 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-lg scrollbar-corner-md w-full mb-6">
        <table className="min-w-full">
          <thead>
          <tr className="text-center text-fadedDenim pb-2 text-xs">
            <th className="col-span-4 text-left py-[14px] px-2 pl-6 sticky left-0 bg-white font-normal">
              Name
            </th>
            <th className="col-span-2 px-2 font-normal text-center">
              Last login
            </th>
            <th className="col-span-2 px-2 font-normal text-center">
              Last completed
            </th>
            <th className="col-span-2 px-2 font-normal text-center">
              Assignments Completed
            </th>
            <th className="col-span-2 px-2 font-normal text-center py-[14px]"></th>
          </tr>
          </thead>

          <tbody className="text-sm text-right text-black">
          {students?.res?.map((student) => (
            <AllStudentsTableRow key={student?.accountId} student={student}/>
          ))}
          </tbody>
        </table>
      </div>

      <div className="flex items-center justify-between">
        <AllStudentSorting className="opacity-0 pointer-events-none" />
        <Pagination
          currentPage={studentsPage}
          onPageChange={handleChangePage}
          totalPages={Math.ceil((students?.totalCount ?? 0) / STUDENTS_PER_PAGE)}
        />
        <AllStudentSorting />
      </div>
    </>
  );
}