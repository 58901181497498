import { useState } from "react";
import { uploadNextSection } from "./header/CompleteSection";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { timerStore } from "../../../store";

const CoffeeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
       strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-coffee">
    <path d="M10 2v2"/>
    <path d="M14 2v2"/>
    <path d="M16 8a1 1 0 0 1 1 1v8a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V9a1 1 0 0 1 1-1h14a4 4 0 1 1 0 8h-1"/>
    <path d="M6 2v2"/>
  </svg>
);

const BrainIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
       strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-brain">
    <path d="M12 5a3 3 0 1 0-5.997.125 4 4 0 0 0-2.526 5.77 4 4 0 0 0 .556 6.588A4 4 0 1 0 12 18Z"/>
    <path d="M12 5a3 3 0 1 1 5.997.125 4 4 0 0 1 2.526 5.77 4 4 0 0 1-.556 6.588A4 4 0 1 1 12 18Z"/>
    <path d="M15 13a4.5 4.5 0 0 1-3-4 4.5 4.5 0 0 1-3 4"/>
    <path d="M17.599 6.5a3 3 0 0 0 .399-1.375"/>
    <path d="M6.003 5.125A3 3 0 0 0 6.401 6.5"/>
    <path d="M3.477 10.896a4 4 0 0 1 .585-.396"/>
    <path d="M19.938 10.5a4 4 0 0 1 .585.396"/>
    <path d="M6 18a4 4 0 0 1-1.967-.516"/>
    <path d="M19.967 17.484A4 4 0 0 1 18 18"/>
  </svg>
);

const EyeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
       strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-eye">
    <path d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0"/>
    <circle cx="12" cy="12" r="3"/>
  </svg>
);

export const BreakScreen = () => {
  const setIsBreak = timerStore(state => state.setIsBreak);
  const [isContinueModalVisible, setIsContinueModalVisible] = useState(false);

  const openModal = () => setIsContinueModalVisible(true);

  const closeModal = () => setIsContinueModalVisible(false);

  const continueNextSection = () => {
    setIsBreak(false);
    uploadNextSection();
    closeModal();
  }

  return (
    <div className="w-full h-full flex items-center justify-center flex-1 m-2 bg-white rounded-md">
      <div className="flex flex-col items-center justify-center overflow-hidden text-black max-w-md">
        <h2 className="text-2xl font-bold text-center mb-6">Great job completing section!</h2>

        <p className="text-center text-gray-600 mb-10">
          It's time for a well-deserved break. Take a moment to relax and recharge before the next section.
        </p>

        <div className="grid grid-cols-3 gap-6 text-center">
          <div className="flex flex-col items-center">
            <CoffeeIcon className="mx-auto h-8 w-8 text-blue-500"/>
            <p className="mt-2 text-sm">Hydrate yourself</p>
          </div>
          <div className="flex flex-col items-center">
            <BrainIcon className="mx-auto h-8 w-8 text-blue-500"/>
            <p className="mt-2 text-sm">Do some stretches</p>
          </div>
          <div className="flex flex-col items-center">
            <EyeIcon className="mx-auto h-8 w-8 text-blue-500"/>
            <p className="mt-2 text-sm">Rest your eyes</p>
          </div>
        </div>

        <button
          className="mt-10 py-2 px-4 rounded-md text-white font-semibold bg-blue-500 hover:bg-blue-600"
          onClick={openModal}
        >
          Continue to Next Section
        </button>

        {isContinueModalVisible && (
          <ConfirmationModal
            title="Continue"
            description="Do you want to continue with the next section right now?"
            checkBoxText="Yes, I do"
            handleConfirm={continueNextSection}
            handleCloseModal={closeModal}
          />
        )}
      </div>
    </div>
  );
}